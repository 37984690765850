import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireStorage } from '@angular/fire/storage';

import { StoreModule, ActionReducer } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { NgbModule, NgbDatepickerModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { CustomerModule } from './customer/customer.module';
import { UploadsModule } from './uploads/uploads.module';
import { RetailerModule } from './retailer/retailer.module';
import { InvoiceModule } from './invoice/invoice.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { LoginComponent } from './login/login.component';
import { TermsComponent } from './terms/terms.component';

import { CanDeactivateGuard } from './services/can-deactivate.guard';
import { ActionTypes } from './services/auth.service';

import { reducers } from './reducers';
import { MomentDateParserFormatter } from './providers/moment.parser-formatter';

import { environment } from '../environments/environment';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SurveysModule } from './surveys/surveys.module';
import { UpdatesModule } from './updates/updates.module';
import { ProductsModule } from './products/products.module';

const domain = environment.domain;

const config = {
  apiKey: environment.apiKey,
  authDomain: `${domain}.firebaseapp.com`,
  databaseURL: `https://${domain}.firebaseio.com`,
  projectId: `${domain}`,
  storageBucket: `${domain}.appspot.com`,
  messagingSenderId: environment.messagingSenderID,
  appId: environment.appID
};

export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === ActionTypes.LOGOUT) {
      state = undefined;
      setTimeout(() => {
        window.location.reload();
      }, 100)
    }
    return reducer(state, action);
  };
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    console.log('state', state);
    console.log('action', action);

    return reducer(state, action);
  };
}

// console.log('IsProd?' + environment.production)
@NgModule({
  declarations: [
    AppComponent,
    UserProfileComponent,
    LoginComponent,
    TermsComponent,
    ForgotPasswordComponent
  ],
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers: [clearState] ,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    BrowserModule,
    BrowserAnimationsModule,
    EffectsModule.forRoot([]),
    AngularFireModule.initializeApp(config),
    AngularFirePerformanceModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    CustomerModule,
    RetailerModule,
    NgbModule,
    NgbDatepickerModule,
    SharedModule,
    UploadsModule,
    InvoiceModule,
    FormsModule,
    SurveysModule,
    UpdatesModule,
    ProductsModule
  ],
  providers: [
    AngularFirestore,
    AngularFireStorage,
    CanDeactivateGuard,
    {provide: NgbDateParserFormatter, useClass: MomentDateParserFormatter}
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }

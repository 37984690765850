// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  sendgrid: {
    key: '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'
  },
  domain: 'vaults-online-dev',
  apiKey: 'AIzaSyCRRnt7aHXrpikYGKeSx5TZIqLJkpgNYq8',
  messagingSenderID: '58336207702',
  appID: '1:58336207702:web:da24e486c78d2362c96cf7',
  stripePublishableKey: 'pk_test_Fwc2YnFkJx7JiXwBOJGmB8FT00jfHbnF45',
  stripeClientId: 'ca_FACvZOUgaF9W1q7kz6AMe4py5Si3t3zq'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

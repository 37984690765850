import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import { customerReducer } from '../customer/customer.reducer';
import { orderReducer } from '../order/order.reducer';
import { staffReducer } from '../staff/staff.reducer';
import { retailerReducer } from '../retailer/retailer.reducer';
import { login, logout } from '../services/auth.service';
export interface State {

}

export const reducers: ActionReducerMap<State> = {
  customer: customerReducer,
  order: orderReducer,
  staff: staffReducer,
  retailer: retailerReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [logout, login] : [logout, login];

import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { StripeACHDebitComponent } from "../stripe-achdebit/stripe-achdebit.component";
import { StripeCreditCardComponent } from "../stripeCreditCard/stripeCreditCard.component";
import { FormGroup, FormControl, FormBuilder } from "@angular/forms";
import { AngularFirestore } from "@angular/fire/firestore";
import { Customer } from "src/app/customer/customer.reducer";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";

import { Retailer } from "src/app/retailer/retailer.reducer";
import { environment } from "../../../environments/environment";
import { AuthService } from "src/app/services/auth.service";
declare var Stripe;
@Component({
  selector: "app-stripe-payment-choice",
  templateUrl: "./stripe-payment-choice.component.html",
  styleUrls: ["./stripe-payment-choice.component.scss"],
})
export class StripePaymentChoiceComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() customer: Customer;
  @Input() retailer: Retailer;
  @Input() isRetailer: boolean;
  @Input() showPaymentOptions: boolean;
  @Output() paymentMethodUpdated: EventEmitter<{ paymentMethod: string }> =
    new EventEmitter();
  @ViewChild(NgbNav, { static: true }) tabs: NgbNav;
  @ViewChild(StripeCreditCardComponent, { static: false })
  credit: StripeCreditCardComponent;
  @ViewChild(StripeACHDebitComponent, { static: false })
  ach: StripeACHDebitComponent;
  stripe;
  source;
  paymentOptions = [];
  paymentOptionError: string;
  errorMessage: string;
  paymentForm: FormGroup;
  // Define a variable to store the saving status
  savingStatus: "saving" | "done" | "error" | null = null;
  constructor(
    private afs: AngularFirestore,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.auth.user$.subscribe((user) => {
      this.paymentForm = this.fb.group({
        paymentMethod: [
          {
            value: "",
            disabled: user.permissionRef.id === "READ_ONLY",
          },
        ],
      });
      if (this.customer) {
        if (!this.customer.paymentMethod) {
          this.updatePaymentMethod("onAccount");
        }
        setTimeout(() => {
          this.paymentForm.controls["paymentMethod"].setValue(
            this.customer.paymentMethod
          );
        });
        // Trigger change detection to update the UI
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      } else {
        setTimeout(() => {
          this.paymentForm.controls["paymentMethod"].setValue(
            this.customer.paymentMethod
          );
        });
        // Trigger change detection to update the UI
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      }
      this.changeDetectorRef.detectChanges();
      this.stripe = Stripe(environment.stripePublishableKey);
      this.form.addControl("amount1", new FormControl(""));
      this.form.addControl("amount2", new FormControl(""));
      if (user.roles.retailer) {
        user.retailerRef
          .get()
          .then((res) => {
            const ret = res.data() as Retailer;
            this.paymentOptions = [
              {
                id: "onAccount",
                value: `On Account - ${
                  ret.additionalCharges.onAccountFee * 100
                }% Charge`,
              },
              {
                id: "ach",
                value: `Bank Account - ${
                  ret.additionalCharges.achFee * 100
                }% Charge`,
              },
              {
                id: "creditCard",
                value: `Credit Card - ${
                  ret.additionalCharges.creditCardFee * 100
                }% Charge`,
              },
            ];
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  async getSource() {
    if (this.ach && this.ach.isFilled()) {
      const promise = this.ach.getSource();
      promise.then((source) => {
        this.source = source;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
      return promise;
    } else if (this.credit && this.credit.isFilled()) {
      const promise = this.credit.getSource();
      promise.then((source) => {
        this.source = source;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
      return promise;
    }
  }
  isFilled() {
    if (this.ach && this.ach.isFilled()) {
      return this.ach.isFilled();
    } else if (this.credit) {
      return this.credit.isFilled();
    } else {
      const amount1 = this.form.get("amount1").value;
      const amount2 = this.form.get("amount2").value;
      return amount1 && amount2;
    }
  }

  hasSource() {
    if (this.source) {
      return true;
    }
    if (this.customer && this.customer.source && this.customer.client_secret) {
      this.stripe
        .retrieveSource({
          id: this.customer.source,
          client_secret: this.customer.client_secret,
        })
        .then((result) => {
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
          this.source = result.source;
          return true;
        });
    } else if (this.customer && this.customer.ba) {
      this.source = {
        bankName: this.customer.bankName,
        last4: this.customer.last4,
      };
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
      if (!this.customer.verified) {
        this.tabs.select("tab-selectbyid1");
      }
      return true;
    } else if (this.isRetailer && this.retailer && this.retailer.ba) {
      this.source = {
        bankName: this.retailer.bankName,
        last4: this.retailer.last4,
      };
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
      if (!this.retailer.verified) {
        this.tabs.select("tab-selectbyid1");
      }
      return true;
    } else {
      return false;
    }
  }

  updatePaymentMethod(paymentMethod: string) {
    this.errorMessage = "";
    this.savingStatus = "saving";
    if (
      (!this.customer.stripeCustomerId || !this.customer.verified) &&
      paymentMethod === "ach"
    ) {
      this.savingStatus = null
      this.errorMessage =
        "Please ensure your bank account details have been entered and verified.";
      // this.paymentForm.controls['paymentMethod'].setValue('onAccount');
    } else if (
      !this.customer.stripeCustomerId &&
      paymentMethod === "creditCard"
    ) {
      this.savingStatus = null
      this.errorMessage =
        "Please enter your credit card details to pay using your card";
      // this.paymentForm.controls['paymentMethod'].setValue('onAccount');
    } else {

    // Emit the selected payment method
    this.paymentMethodUpdated.emit({ paymentMethod });

    const updateData = {
      paymentMethod: paymentMethod,
    };

    const ref = this.afs.doc<Customer>(`customers/${this.customer.id}`);
    ref
      .update(updateData)
      .then(() => {
        // Set the saving status to 'done' after a brief delay
        this.savingStatus = "done";
        setTimeout(() => {
          this.savingStatus = null; // Reset the saving status after a certain time
        }, 3000);
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      })
      .catch((error) => {
        // Set the saving status to 'error' after a brief delay
        this.savingStatus = "error";
        setTimeout(() => {
          this.savingStatus = null; // Reset the saving status after a certain time
        }, 3000);
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as adminActions from '../admin/admin.actions';
import * as retailerActions from '../retailer/retailer.actions';
import * as customerActions from '../customer/customer.actions';
import * as fromAdmin from '../admin/admin.reducer';
import * as fromRetailer from '../retailer/retailer.reducer';
import * as fromCustomer from '../customer/customer.reducer';
import { AuthService } from 'src/app/services/auth.service';
import moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Statement } from '../models/models';
import { STATEMENT_DATE_FORMAT } from '../utils';
import { User } from '../models/user.model';

let domain = environment.domain;

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html'
})
export class StatementsComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  customers$: Observable<any>;
  public statementsData$: Observable<any>;
  public statements: Statement[];
  public name: string;
  private target: string;
  private userId: string;
  private user: User;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private adminStore: Store<fromAdmin.State>,
    private retailerStore: Store<fromRetailer.State>,
    private customerStore: Store<fromCustomer.State>) { }

  ngOnInit() {
    this.auth.user$.pipe(
      tap(user => {
        if (user.roles.customer || user.roles.retailer) {
          this.customerStore.dispatch(new customerActions.Query(user));
        }
        if (user.roles.retailer || user.roles.admin) {
          this.retailerStore.dispatch(new retailerActions.Query());
        }
        if (user.roles.admin || user.roles.retailer) {
          this.adminStore.dispatch(new adminActions.Query());
        }
      })
    ).subscribe(user => {
      this.user = user;
    });
    this.route.paramMap.pipe(
      map(params => {
        return {
          id: params.get('id'),
          target: params.get('target')
        };
      })
    ).subscribe(params => {
      this.target = params.target;
      this.userId = params.id;
      this.setStatements();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private setStatements() {
    this.name = 'My Statements';
    if (this.target === 'customer') {
      const customerStore$ = this.customerStore.select(fromCustomer.selectAll).pipe(
        map(customers => {
          return customers.find(c => c.id === this.userId)?.statements;
        })
      );
      this.statementsData$ = customerStore$.pipe(
        map((result) => {
          return this.setOrderedStatements(result);
        })
      );
    } else if (this.target === 'retailer') {
      const retailerStore$ = this.retailerStore.select(fromRetailer.selectAll).pipe(
        map(retailers => {
          return retailers.find(retailer => retailer.id === this.userId)?.statements;
        })
      );
      this.statementsData$ = retailerStore$.pipe(
        map((result) => {
          return this.setOrderedStatements(result);
        })
      );
    } else if (this.target === 'vaultwrx') {
      const adminStore$ = this.adminStore.select(fromAdmin.selectAll).pipe(
        map(admins => admins[0])
      );
      const retailerStore$ = this.retailerStore.select(fromRetailer.selectAll).pipe(
        map(retailers => {
          return retailers.find(retailer => retailer.id === this.userId);
        })
      );
      this.statementsData$ = combineLatest([adminStore$, retailerStore$]).pipe(
        map(([admin, retailer]) => {
          this.name = 'Vaultwrx Invoices';
          return this.setOrderedStatements(admin?.invoices.filter(invoice => invoice.retailerRef.id === retailer?.id));
        })
      );
    } else if (this.target === 'admin') {
      const adminStore$ = this.adminStore.select(fromAdmin.selectAll).pipe(
        map(admins => {
          return admins[0].statements;
        })
      );
      this.statementsData$ = adminStore$.pipe(
        map((result) => {
          return this.setOrderedStatements(result);
        })
      );
    } else {
      this.router.navigate(['landing']);
    }
  }

  private setOrderedStatements(unorderedStatements: Statement[]) {
    let orderedStatements: Statement[] = [];
    if(unorderedStatements) {
      orderedStatements = unorderedStatements.sort((data1, data2) => {
        return moment(data2.date, STATEMENT_DATE_FORMAT).diff(moment(data1.date, STATEMENT_DATE_FORMAT));
      });

      orderedStatements.forEach(statement => {
        console.log("Statement: ", statement)

        firebase.storage().refFromURL(`gs://${domain}.appspot.com/${statement.path}`).getDownloadURL().then((url) => {
          console.log("Statment path: ", url);

          statement.path = url;
        })
      });
    }
    return orderedStatements;
  }
}
